import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from '~components'
import Promo from "./style"
export default function PromoSection() {
  return (
    <Promo>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-12">
            <Promo.ContentBox className="text-center">
              <Promo.Box>
                <Promo.Title as="h2" fontColor="#fff" mb="25px">
                  We have proven that we are the best at what we do. which is, Manufacturing Stainless Steel Hotel Kitchen Equipment Sri Lanka.
            </Promo.Title>
                <Promo.Text fontColor="#fff" mb="30px">
                  If you need even more customization or special dimensions
                  we can design and build any stainless steel fabrications you need
            </Promo.Text>
                <Promo.Button as={Link} to="https://wa.me/94718249490">Get started</Promo.Button>
              </Promo.Box>
            </Promo.ContentBox>
          </Col>
        </Row>
      </Container>
    </Promo>

  )
}