import styled from 'styled-components/macro';
import { Box, Heading,Button,Paragraph } from '~styled';

const Service = styled(Box)`
padding-top: 55px;
    padding-bottom: 36px;

    @media (min-width:768px) {
        padding-top: 75px;
        padding-bottom: 55px;
    }

    @media (min-width:992px) {
        padding-top: 130px;
        padding-bottom: 105px;
    }
`
Service.Title = styled(Heading)`
    font-size: 32px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.3;
    @media (min-width:768px) {
      font-size: 38px;
    }

    @media (min-width:992px) {
      font-size: 48px;
    }
`
Service.Subtitle = styled(Heading)`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.75;
    color: var(--bs-base-secondary);
`
Service.Text = styled(Paragraph)`
    font-size: 18px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.77777;
`
Service.Button = styled(Button)`
color:#fff;
    border-color: var(--bs-base-primary);
    background-color:var(--bs-base-primary);
    box-shadow:0 20px 20px rgb(237 110 7 / 30%);
    border-radius:500px;
    &:hover{
        box-shadow:0 20px 20px rgb(237 110 7 / 0%);
        color:#fff;

    }
`
Service.Box = styled(Box)``
export default Service;