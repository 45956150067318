import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ServiceCard from './Components/Card'
import Service from "./style"

import { Images } from "~data"
export default function ServicesSection() {
  return (
    <Service>
      <Container>
        <Row>
          <Col xs="12" className="col-xl-5 col-lg-6 col-md-9 col-xs-10 order-1 order-lg-1">
            <Service.Subtitle as="p" mb="22px">Our Services</Service.Subtitle>
            <Service.Title as="h2" mb="40px">
              What We Offer for Stainless Steel Hotel Kitchen Equipment Sri Lanka
          </Service.Title>
            {/* <Service.Button pl="15px" pr="15px" mb="25px" sizeY="56px" sizeX="168px" className="btn" href="#">Learn More</Service.Button> */}
          </Col>
          <Col xs="12" className="col-xxl-6 offset-xxl-1 col-xl-7 col-lg-6 col-md-11 order-2 order-lg-2">
            <Service.Box mlXL="35px" mb="20px" mbLG="0px">
              <Service.Text mb="40px">
                High-quality stainless steel kitchen equipment and bespoke
              <br className="d-none d-md-block d-lg-none d-xl-block" />
              fabrication solutions to the commercial hotel kitchen & catering industries.
            </Service.Text>
              <ServiceCard
                // image={Images.DigiTalagency.servicesIcon1}
                title="Stainless Steel Kitchen Equipment"
                text="We understand the importance of cleanliness in food preparation areas. Our stainless steel kitchen equipment is
durable and easy to maintain, allowing you to achieve the highest standards of hygiene." mb="25px" />
              <ServiceCard
                //  image={Images.DigiTalagency.servicesIcon2}
                title="Commercial Hotel Equipment"
                text="We design, manufacture, deliver and install hotel equipment to customers across the Sri Lanka." mb="25px" />
              <ServiceCard
                // image={Images.DigiTalagency.servicesIcon3}
                title="Stainless Steel Catering Equipment"
                text="We stock a range of affordable, robust and attractive catering equipment. Choose from various options, to get the perfect stainless steel kitchen that suits your needs!" mb="25px" />
            </Service.Box>
          </Col>
        </Row>
      </Container>
    </Service>

  )
}