import styled from 'styled-components/macro';
import { Box, Heading,Button,Paragraph } from '~styled';
const Extra = styled(Box)`
padding-top: 55px;
padding-bottom: 60px;
@media (min-width:768px) {
  padding-top: 75px;
  padding-bottom: 80px;
}

@media (min-width:992px) {
  padding-top: 135px;
  padding-bottom: 110px;
}
`

Extra.Title = styled(Heading)`
    font-size: 32px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.3;
    @media (min-width:768px) {
      font-size: 38px;
    }

    @media (min-width:992px) {
      font-size: 48px;
    }
`

Extra.SecTitle = styled(Heading)`
      font-size: 21px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 30px;
`

Extra.Subtitle = styled(Heading)`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.75;
    color: var(--bs-base-secondary);
`



Extra.Text = styled(Paragraph)`
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.77777;
    @media (min-width:768px) {
        font-size: 18px;
    }
`

Extra.Image = styled(Box)`
@media (min-width: 992px) {
    margin-right: 30px;
}
}
`
Extra.Contentlist = styled(Box)``

Extra.List = styled(Box)`
        position: relative;
        z-index: 1;
        padding-left: 30px;
        margin-bottom: 20px;
        color: var(--color-headings-2);
        font-size: 16px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.75;

&:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 18px;
    background-color: #61308a;
    left: 10px;
    top: 4px;
}
`
Extra.Box = styled(Box)``


export default Extra;