import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Extra from './style'
import { StaticImage as Img } from 'gatsby-plugin-image'
import SectionTitle from './Components/SectionTitle'
import CounterBlock from './Components/CounterBlock'

export default function ExtraSection() {
  return (
    <Extra backgroundColor="#f3f4f6">
      <Container>
        <Row>
          <Col className="col-xl-8 col-lg-10">
            <Extra.Box>
              <Extra.Subtitle as="p" mb="22px">Our Specialty</Extra.Subtitle>
              <Extra.Title as="h2" mb="40px">
                Why our stainless steel hotel kitchen equipment sri lanka is special
              </Extra.Title>
            </Extra.Box>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="col-lg-6 col-md-12 mb-5 mb-lg-7">
            <Extra.Box>
              <Extra.SecTitle as="h3" >
                Our products, stainless steel hotel kitchen equipment is hygienic
              </Extra.SecTitle>
              <Extra.Text mrLG="30px">
                The only metal that is biologically neutral and therefore an antibacterial material, is Stainless steel. It's the only metal that does not modify the color, the fragrances, or the
                flavors of food. Also, it does not release toxic substances which could contaminate it.
                This is why we've always used it in the kitchen as well as to design industrial kitchens,
                where hygiene and perfect food preservation are fundamental requisites.
          </Extra.Text>
              <Extra.Text mrLG="30px">
                Sunru Fabrications only makes kitchens in stainless steel. To guarantee every customer can benefit from the same performance and safety levels as a professional kitchen in the comfort of their own home.
          </Extra.Text>
            </Extra.Box>
          </Col>

          <Col xs="12" className="col-lg-6 col-md-12 mb-5 mb-lg-7">
            <Extra.Box>
              <Extra.SecTitle as="h3" >
                Stainless steel hotel kitchen equipment is easy to clean
              </Extra.SecTitle>
              <Extra.Text mrLG="30px">
                Stainless steel comes with its proven characteristic feature that is extremely easy to clean thanks to the smooth surface which is immune to bacteria. This makes it the preferred material for professional kitchens, where cleaning tasks need to be facilitated as much as possible.
            </Extra.Text>
              <Extra.Text mrLG="30px">
                The only places where dirt and bacteria can build up in a stainless steel kitchen are the inner corners of compartments and the joints between one module and the next. Before choosing a stainless steel kitchen, make sure that it is easy to clean, including inside the compartments.
            </Extra.Text>
              <Extra.Text mrLG="30px">
                Equipment built at Sunru Fabrications is welded without joints and the shelves can be pulled out. Moreover, the inner compartments can be made with rounded corners, to facilitate cleaning tasks as much as possible.
            </Extra.Text>
            </Extra.Box>
          </Col>
        </Row>

        <Row>
          <Col xs="12" className="col-lg-6 col-md-12 mb-5 mb-lg-7">
            <Extra.Box>
              <Extra.SecTitle as="h3" >
                Stainless steel hotel kitchen equipment is accepted and a favorite among great chefs.
              </Extra.SecTitle>
              <Extra.Text mrLG="30px">
                Great chefs have always used stainless steel kitchens to prepare their dishes. They prefer them in their restaurants, but at home too, in their private life or when they are cooking for guests. They do so because they are practical, hygienic, ergonomic, hard-wearing, easy to use, and clean.
              </Extra.Text>
              <Extra.Text mrLG="30px">
                Choosing a stainless steel kitchen for your hotel means demanding the best the market has to offer. Especially if the kitchen is equipped with those solutions and accessories, which have been tested “on the field” by great chefs, that make cooking simpler and more enjoyable for everyone.
              </Extra.Text>
            </Extra.Box>
          </Col>


          <Col xs="12" className="col-lg-6 col-md-12 mb-5 mb-lg-7">
            <Extra.Box>
              <Extra.SecTitle as="h3" >
                Our stainless steel hotel kitchen equipment are 100% recyclable
              </Extra.SecTitle>
              <Extra.Text mrLG="30px">
                Stainless steel is an environmentally-friendly and sustainable material since it is 100% recyclable. But that’s not all: its life cycle is near eternal since it can be recycled endless times without ever losing its original properties. Stainless steel is an irreplaceable material that guarantees the sustainable consumption of resources.
            </Extra.Text>
              <Extra.Text mrLG="30px">
                For this reason, unlike almost all the other kitchens (which are only partially recyclable or often not at all), stainless steel kitchens can be 100% recycled. At the end of their useful life, they return to being pure steel, without causing our planet any problems. But don’t worry, as we will see in point 6, a stainless steel kitchen is so durable that this problem will never arise… But it is still great to be able to make a choice that helps the environment and makes the world more sustainable. For us and our children!
            </Extra.Text>
            </Extra.Box>
          </Col>
        </Row>

        <Row>
          <Col xs="12" className="col-lg-6 col-md-12 mb-5 mb-lg-7">
            <Extra.Box>
              <Extra.SecTitle as="h3" >
                For outdoor use choose our stainless steel hotel kitchen equipment.  We say Yes!.
              </Extra.SecTitle>
              <Extra.Text mrLG="30px">
                The fun of cooking for guests outside as well, perhaps by the sea, on a poolside, or the panoramic terrace of a hotel has encouraged the widespread use of stainless steel kitchens. Indeed, stainless steel is a material that is resistant to corrosion and extremely high temperatures. It is weather-resistant and extremely strong. It can withstand UV rays and harsh winter weather.
              </Extra.Text>
            </Extra.Box>
          </Col>
        </Row>
      </Container>
    </Extra>

  )
}