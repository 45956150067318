import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from '~components'
import { Images } from '~data'
import Hero from './style'

export default function HeroSection() {
  return (
    <Hero style={{ backgroundImage: `url(${Images.Slices.heroImg})` }}>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col className="col-xl-9">
            <Hero.Content className="text-center">
              {/* <Hero.Icon><i className="fa fa-bell" /></Hero.Icon> */}
              <Hero.Title as="h1" fontColor="#fff">Best Stainless Steel Hotel Kitchen Equipment Sri Lanka</Hero.Title>
              <Hero.Text as="p" fontColor="#fff">
                Sunru Fabrications is one of the well-known company for Stainless Steel Hotel Kitchen Equipment Sri Lanka.
          <br className="d-none d-xs-block" />
          We offer the complete precision stainless steel fabrication and finishing service. As we have the best stainless steel fabrication, specialists.
              </Hero.Text>
              {/* <Hero.Button as={Link} to="/">Get Started</Hero.Button> */}
            </Hero.Content>
          </Col>
        </Row>
      </Container>
    </Hero>

  )
}