import React from 'react'
import Counter from './style'

export default function CounterBlock({ ...rest }) {
    return (
        <Counter {...rest}>
            <Counter.Single>
                <Counter.Title as="h3"><span className="counter">20+</span></Counter.Title>
                <Counter.Text>Years<br className="d-none d-xs-block" /> Experience</Counter.Text>
            </Counter.Single>
            <Counter.Single>
                <Counter.Title as="h3"><span className="counter">98.9</span>%</Counter.Title>
                <Counter.Text>Customer<br className="d-none d-xs-block" /> Satisfaction</Counter.Text>
            </Counter.Single>
            <Counter.Single>
                <Counter.Title as="h3"><span className="counter">100</span>+</Counter.Title>
                <Counter.Text>Companies<br className="d-none d-xs-block" /> Work with us</Counter.Text>
            </Counter.Single>
        </Counter>

    )
}