import { Link } from '~components'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Images } from '~data'
import Footer from "./style"

const copyRightYear = () => {
    return new Date().getFullYear() == 2002 ? '2002' : '2002 - ' + new Date().getFullYear();
}

export default function FooterFour() {
    return (
        <Footer>
            <Container>
                {/* <Footer.Box pb="30px">
                    <Row className="justify-content-center justify-content-lg-between">
                        <Col xs="12" className="col-12">
                            <Footer.Logo>
                                <Footer.Box mb="30px" mbMD="0">
                                    <Link to="#">
                                        <img className="mx-auto" src={Images.FooterSix.LogoBlack} alt="logo" />
                                    </Link>
                                </Footer.Box>
                            </Footer.Logo>
                        </Col>
                        <Col xs="12" className="col-lg-8 col-md-7">
                            <Footer.Menu>
                                <Footer.MenuItems><a href="#">Home</a></Footer.MenuItems>
                                <Footer.MenuItems> <a href="#">About Us</a> </Footer.MenuItems>
                                <Footer.MenuItems><a href="#">Blog</a></Footer.MenuItems>
                                <Footer.MenuItems><a href="#">Contact</a>
                                </Footer.MenuItems>
                            </Footer.Menu>
                        </Col>
                    </Row>
                </Footer.Box> */}
                <Footer.Copyright>
                    <Footer.CopyrightText>© {copyRightYear()} Sunru Fabrications. All Rights Reserved.</Footer.CopyrightText>
                    <Footer.SocialShare>
                        <Footer.SocialShareItem>
                            <a href="https://www.facebook.com/sunrufabrication">
                                <i className="fab fa-facebook-square" />
                            </a>
                        </Footer.SocialShareItem>
                        <Footer.SocialShareItem>
                            <a href="https://wa.me/94718249490">
                                <i className="fab fa-whatsapp" />
                            </a>
                        </Footer.SocialShareItem>
                    </Footer.SocialShare>
                </Footer.Copyright>
                <Footer.CopyrightText pb="40px" className="text-center">A work of <a className="text-base-secondary hover:text-base-primary" href="https://deviitor.com">Deviitor D&D</a> 2022.</Footer.CopyrightText>
            </Container>
        </Footer>
    )
}