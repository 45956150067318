import React from "react";
import { PageWrapper } from "~components/Core";
import HeroSection from '~sections/slices/Hero'
import ServicesSection from '~sections/slices/Services'
import AboutSection from '~sections/slices/About'
import PortfolioSection from '~sections/slices/Portfolio'
import PromoSection from '~sections/slices/Promo'
import HeaderButton from '~sections/slices/Header'
import FooterFour from '~sections/slices/FooterFour'
import ExtraSection from "~sections/slices/Extra";
import { Link } from '~components'

const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid: false,
  buttonBlock: <HeaderButton as={Link} to="https://wa.me/94718249490" className="d-none d-sm-flex" children="WhatsApp" />,
  darkLogo: false,
}

export default function Digital() {
  return (
    <PageWrapper headerConfig={header}>
      <HeroSection />
      <PortfolioSection />
      <AboutSection />
      <ServicesSection />
      <ExtraSection />
      <PromoSection />
      <FooterFour />
    </PageWrapper>
  )
}
