import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { motion, usePresence, AnimatePresence } from 'framer-motion'
import { Images } from '~data'
// import PortfolioCard from './Components/Card'
import Portfolio from "./style"
import { StaticImage as Img } from 'gatsby-plugin-image'
import Card from "./Components/Card/style"

const portfolioData = [
  {
    image: Images.Slices.portFolioImg1,
    titleSmall: "KAKTUS",
    title: "Candy Store",
    id: "1",
    tagi: ["all", "development"]
  },
  {
    image: Images.Slices.portFolioImg2,
    titleSmall: "KAKTUS",
    title: "Candy Store",
    id: "2",
    tagi: ["all"]
  },
  {
    image: Images.Slices.portFolioImg3,
    titleSmall: "KAKTUS",
    title: "Candy Store",
    id: "3",
    tagi: ["all"]
  },
  {
    image: Images.Slices.portFolioImg4,
    titleSmall: "KAKTUS",
    title: "Candy Store",
    id: "4",
    tagi: ["all"],
  },
  {
    image: Images.Slices.portFolioImg5,
    titleSmall: "KAKTUS",
    title: "Candy Store",
    id: "5",
    tagi: ["all"],
  },
  {
    image: Images.Slices.portFolioImg6,
    titleSmall: "KAKTUS",
    title: "Candy Store",
    id: "6",
    tagi: ["all"],
  }
]

const FilterButton = ({ name, handleSetTag, tagActive, children }) => {
  return (
    <Portfolio.NavItem
      className={`btn-reset ${tagActive === name ? "active" : ""}`}
      onClick={() => handleSetTag(name)}
    >
      {children}
    </Portfolio.NavItem>
  )
}

export default function PortfolioSection({ ...rest }) {

  const [tag, setTag] = React.useState("all")
  const [filteredImages, setFilteredImages] = React.useState([])


  const img1 = `../../../assets/image/slices/canopy-hood-stainless-steel.jpg`;
  const img2 = `../../../assets/image/slices/kitchen-table-stainless-steel.jpg`;
  const img4 = `../../../assets/image/slices/panty-for-kitchen.jpg`;
  const img6 = `../../../assets/image/slices/sink-for-kitchen.jpg`;
  const img3 = `../../../assets/image/slices/stainless-steel-bain-marie.jpg`;
  const img5 = `../../../assets/image/slices/stainless-steel-tanks.jpg`;

  React.useEffect(() => {
    tag === "all"
      ? setFilteredImages(portfolioData)
      : setFilteredImages(portfolioData.filter(image => image.tagi.find((item) => item === tag)))
  }, [tag])
  return (
    <Portfolio {...rest}>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs="12" className="col-xl-7 col-lg-5 col-md-8 col-xs-9">
            <Portfolio.Box mbLG="45px" mb="10px">
              <Portfolio.Subtitle as="p" mb="13px">Our Recent Works</Portfolio.Subtitle>
              <Portfolio.Title as="h2">Main items for a stainless steel hotel kitchen sri lanka</Portfolio.Title>
            </Portfolio.Box>
          </Col>
          <Col xs="12" className="col-xl-5 col-lg-7 col-md-8">
            {/* <Portfolio.Nav className="mb-5 mb-lg-0">
          <FilterButton className="is-checked" name="all" tagActive={tag} handleSetTag={setTag}>All</FilterButton>
          <FilterButton name="work" tagActive={tag} handleSetTag={setTag}>Design</FilterButton>
          <FilterButton name="development" tagActive={tag} handleSetTag={setTag}> Development</FilterButton>
          <FilterButton name="marketing" tagActive={tag} handleSetTag={setTag}> Marketing</FilterButton>
          <FilterButton name="seo" tagActive={tag} handleSetTag={setTag}>SEO</FilterButton>
        </Portfolio.Nav> */}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="12" className="col-md-12 col-xs-10">
            <Row className="justify-content-start">
              {/* <AnimatePresence> */}
              <ItemCol className={`col-lg-4 col-md-6 col-sm-10 is-animated`} key={2}>
                <Card
                  {...rest}>
                  <Img className="w-100" src={img2} title="stainless steel hot table" alt="stainless steel hot table" />
                </Card>
              </ItemCol>
              <ItemCol className={`col-lg-4 col-md-6 col-sm-10 is-animated`} key={1}>
                <Card
                  {...rest}>
                  <Img className="w-100" src={img1} title="stainless steel canopy hood" alt="stainless steel canopy hood" />
                </Card>
              </ItemCol>
              <ItemCol className={`col-lg-4 col-md-6 col-sm-10 is-animated`} key={3}>
                <Card
                  {...rest}>
                  <Img className="w-100" src={img3} title="bain marie" alt="stainless steel bain marie" />
                </Card>
              </ItemCol>
              <ItemCol className={`col-lg-4 col-md-6 col-sm-10 is-animated`} key={4}>
                <Card
                  {...rest}>
                  <Img className="w-100" src={img4} title="table pantry" alt="stainless steel table pantry" />
                </Card>
              </ItemCol>
              <ItemCol className={`col-lg-4 col-md-6 col-sm-10 is-animated`} key={5}>
                <Card
                  {...rest}>
                  <Img className="w-100" src={img5} title="tanks" alt="stainless steel tanks" />
                </Card>
              </ItemCol>
              <ItemCol className={`col-lg-4 col-md-6 col-sm-10 is-animated`} key={6}>
                <Card
                  {...rest}>
                  <Img className="w-100" src={img6} title="sink" alt="stainless steel sink" />
                </Card>
              </ItemCol>
              {/* {filteredImages.map(
                  ({ image, titleSmall, title, tagi, id }, index) => {
                    return (
                      <ItemCol className={`col-lg-4 col-md-6 col-sm-10 ${tagi.find((item) => item === tag) | tag === "all" ? "is-animated" : ""} `} key={index + "pm-c4"}>
                        <PortfolioCard id={id} image={image} title="Agency Brand guide & Development" text="UX and UI, Design, Development" mt="25px"/>

                      </ItemCol>
                    )
                  }
                )} */}
              {/* </AnimatePresence> */}
            </Row>
          </Col>
        </Row>
      </Container>
    </Portfolio>

  )
}


const transition = { type: 'spring', stiffness: 500, damping: 50, mass: 1 }

function ItemCol({ children, onClick, color, ...rest }) {
  const [isPresent, safeToRemove] = usePresence()

  const animations = {
    layout: true,
    initial: 'out',
    style: {
      position: isPresent ? 'static' : 'absolute'
    },
    animate: isPresent ? 'in' : 'out',
    whileTap: 'tapped',
    variants: {
      in: { scale: 1, opacity: 1 },
      out: { scale: 0, opacity: 0 },
      // tapped: { scale: 0.98, opacity: 0.5, transition: { duration: 0.1 } }
    },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition
  }

  return (
    <motion.div {...animations} {...rest}>
      {children}
    </motion.div>
  )
}


{/* <ItemCol className={`col-lg-4 col-md-6 col-sm-10 ${tagi === tag | tag === "all" ?  "is-animated" :""} `}  key={index + "pm-c4"}> */ }